/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmailsByDateAndBrandCodeQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.EmailsByDateAndBrandCodeInput>;
}>;


export type EmailsByDateAndBrandCodeQuery = { __typename?: 'Query', emailsByDateAndBrandCode?: Array<{ __typename?: 'EmailMessage', id?: string | null, brandCode?: string | null, meetingId?: string | null, meetingDate?: string | null, messageId?: string | null, userEmail?: string | null, status?: string | null, createdAt?: string | null, updatedAt?: string | null } | null> | null };


export const EmailsByDateAndBrandCodeDocument = gql`
    query EmailsByDateAndBrandCode($input: EmailsByDateAndBrandCodeInput) {
  emailsByDateAndBrandCode(input: $input) {
    id
    brandCode
    meetingId
    meetingDate
    messageId
    userEmail
    status
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useEmailsByDateAndBrandCodeQuery__
 *
 * To run a query within a React component, call `useEmailsByDateAndBrandCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailsByDateAndBrandCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailsByDateAndBrandCodeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailsByDateAndBrandCodeQuery(baseOptions?: Apollo.QueryHookOptions<EmailsByDateAndBrandCodeQuery, EmailsByDateAndBrandCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailsByDateAndBrandCodeQuery, EmailsByDateAndBrandCodeQueryVariables>(EmailsByDateAndBrandCodeDocument, options);
      }
export function useEmailsByDateAndBrandCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailsByDateAndBrandCodeQuery, EmailsByDateAndBrandCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailsByDateAndBrandCodeQuery, EmailsByDateAndBrandCodeQueryVariables>(EmailsByDateAndBrandCodeDocument, options);
        }
export type EmailsByDateAndBrandCodeQueryHookResult = ReturnType<typeof useEmailsByDateAndBrandCodeQuery>;
export type EmailsByDateAndBrandCodeLazyQueryHookResult = ReturnType<typeof useEmailsByDateAndBrandCodeLazyQuery>;
export type EmailsByDateAndBrandCodeQueryResult = Apollo.QueryResult<EmailsByDateAndBrandCodeQuery, EmailsByDateAndBrandCodeQueryVariables>;
export const namedOperations = {
  Query: {
    EmailsByDateAndBrandCode: 'EmailsByDateAndBrandCode'
  }
}