import { DateHelpers, lastNightTableDates } from '@/helpers/dateHelpers';

import { FaInfoCircle } from 'react-icons/fa';
import { LastNightTooltip } from '@/reports/LastNightTable';
import { Meeting } from '@/graphql/types';
import { ReportDataType } from '@/features/reports/context/report-data-context';
import { SectionContainer } from '@/features/reports/components/section-container';
import Table from '@/components/Table';

type LastNightSummaryProps = {
  lastNightData: ReportDataType['lastNightData'];
  loadingReport: boolean;
  meeting?: Meeting;
};

export const LastNightSummary = ({
  lastNightData,
  loadingReport,
  meeting,
}: LastNightSummaryProps) => {
  const tableDates = lastNightTableDates(
    meeting?.meetingDate || DateHelpers.today()
  );

  return (
    <SectionContainer
      anchorId='last-night'
      title='Last Night Summary'
      toolbar={
        <div>
          <FaInfoCircle
            className='text-blue-500'
            data-tip
            data-for='last-night-summary'
          />
          <LastNightTooltip tableDates={tableDates} />
        </div>
      }
    >
      {loadingReport ? <span>Loading...</span> : <Table {...lastNightData} />}
    </SectionContainer>
  );
};
