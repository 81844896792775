import ReactTooltip from 'react-tooltip';

type LoadingTooltipProps = {
  buttonType?: string;
  loading: Array<string>;
};

export const LoadingTooltip = ({
  buttonType,
  loading,
}: LoadingTooltipProps) => {
  return (
    <ReactTooltip id='loading-data' aria-haspopup='true'>
      <span className='text-xs'>
        {loading.length
          ? 'Loading:'
          : buttonType
          ? `${buttonType} Meeting`
          : ''}
      </span>
      <ul className='mb-1'>
        {loading.length
          ? loading.map((item) => (
              <li key={item} className='text-xs'>
                {item}
              </li>
            ))
          : null}
      </ul>
    </ReactTooltip>
  );
};
