import { RateAnalysis, RateShopData } from '@/core/ai/schemas';

export function analyzeRateShopData(data?: RateShopData): RateAnalysis {
  const analysis: RateAnalysis = {
    byDate: {},
    byCompetitor: {},
  };

  // Process each row (date)
  data?.rows.forEach((row) => {
    const dateCell = row.find((cell) => cell.dataKey === 'stay_date');
    const dowCell = row.find((cell) => cell.dataKey === 'dow');
    // Combine date and day of week
    const date = dateCell?.stayDate
      ? `${dateCell.stayDate} ${dowCell?.value || ''}`
      : '';
    const clientData = row.find((cell) => cell.meta.header === 'Client');
    const clientRate = clientData?.value || 0;

    // Skip if no client rate or invalid date
    if (!clientRate || !date) return;

    // Initialize date entry if needed
    if (!analysis.byDate[date]) {
      analysis.byDate[date] = {
        noRateCompetitors: [],
        significantChanges: [],
      };
    }

    // Process each competitor in the row
    row.forEach((cell) => {
      // Skip non-competitor cells
      if (!cell.meta.competitor_name || cell.meta.header === 'Client') return;

      const competitorName = cell.meta.competitor_name;

      // Initialize competitor entry if needed
      if (!analysis.byCompetitor[competitorName]) {
        analysis.byCompetitor[competitorName] = {
          noRatesDates: [],
          significantChanges: [],
        };
      }

      // Check for no rate availability
      if (!cell.value && clientRate > 0) {
        analysis.byDate[date].noRateCompetitors.push({
          competitorName,
          clientRate,
        });
        analysis.byCompetitor[competitorName].noRatesDates.push({
          date,
          clientRate,
        });
      }

      // Check for significant rate changes
      if (cell.meta.data?.changes?.[0]) {
        const prevRate = cell.meta.data.changes[0].change.value;
        const newRate = cell.value;
        const rateDiff = Number((newRate - prevRate).toFixed(2));

        // Consider rate changes over $10 or availability changes
        if (
          Math.abs(rateDiff) >= 10 ||
          (prevRate > 0 && newRate === 0) ||
          (prevRate === 0 && newRate > 0)
        ) {
          const change = {
            prevRate,
            newRate,
            rateDiff,
          };

          // Add to date-based analysis
          analysis.byDate[date].significantChanges.push({
            competitorName,
            ...change,
          });

          // Add to competitor-based analysis
          analysis.byCompetitor[competitorName].significantChanges.push({
            date,
            ...change,
          });
        }
      }
    });
  });

  return analysis;
}
