import { LastNightTableDates, formatDate } from '@/helpers/dateHelpers';

import ReactTooltip from 'react-tooltip';
import Table from '@/components/Table';
import { useReportData } from '@/features/reports/context/report-data-context';

type TooltipProps = {
  tableDates: LastNightTableDates;
};

export const LastNightTooltip = ({ tableDates }: TooltipProps) => {
  return (
    <ReactTooltip id='last-night-summary' aria-haspopup='true'>
      <div>
        <p>
          <strong>Last Night Summary Table</strong>
        </p>
        <br />
        <ul>
          <li>
            MTD - This is the total from the 1st of the month up to Last Night
          </li>
          <li>
            MTD LY - This is the total from the same day of the month last year
            up to last night last year.
          </li>
        </ul>
        <br />
        <p>
          <em>{`Example. If today is ${formatDate(
            tableDates.baseDate,
            'MMMM D, YYYY'
          )}`}</em>
        </p>
        <ul>
          <li>{`MTD covers ${formatDate(tableDates.mtdStart)} - ${formatDate(
            tableDates.mtdEnd
          )} for actuals`}</li>
          <li>
            {` MTD LY covers ${formatDate(
              tableDates.mtdStartLy
            )} - ${formatDate(tableDates.mtdEndLy)} for actuals (not same point
            in time)`}
          </li>
        </ul>
      </div>
    </ReactTooltip>
  );
};

export const LastNightTable = () => {
  const { lastNightData } = useReportData().data;
  return <Table {...lastNightData} />;
};
