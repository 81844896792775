import { RateCellRenderer } from '@/features/rate_shops/components/rate-cell-renderer';
import { ReportDataType } from '@/features/reports/context/report-data-context';
import { SectionContainer } from '@/features/reports/components/section-container';
import Table from '@/components/Table';

type FiveDayRatesProps = {
  fiveDayRatesData: ReportDataType['fiveDayRatesData'];
  loadingRateShop: boolean;
  loadingReport: boolean;
  startDate: string;
};

export const FiveDayRates = ({
  fiveDayRatesData,
  loadingRateShop,
  loadingReport,
}: FiveDayRatesProps) => {
  return (
    <SectionContainer
      anchorId='five-day-rates'
      title='5-day Rate Outlook (lowest non-qualified rate)'
    >
      {loadingRateShop || loadingReport ? (
        <span>Loading...</span>
      ) : (
        <Table
          customCellAlignment={'text-center 3xl:text-left'}
          headers={fiveDayRatesData.headers}
          rows={fiveDayRatesData.rows}
          renderer={RateCellRenderer}
        />
      )}
    </SectionContainer>
  );
};
