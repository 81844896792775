import DataGrid from '../../../components/DataGrid';
import { DateHelpers } from '../../../helpers/dateHelpers';
import { OtaiLowestRatesQuery } from '../../overview-table/gql/_gen_/rate-shop.gql';
import { useTable } from '../../overview-table/hooks/use-table';

type RateDetailTableProps = {
  rateData: OtaiLowestRatesQuery;
  brandCode: string;
};

const RateDataTable = ({ rateData, brandCode }: RateDetailTableProps) => {
  const { compSet, dailyRates } = rateData;

  const tableCols = compSet?.length ? compSet : [];

  const { headers, rows } = useTable({
    customHeaders: null,
    data: {
      dailyRates,
      compSet,
    },
    dateSegment: 'day',
    startDate: DateHelpers.today(),
    endDate: DateHelpers.fromToday(365),
    detailCols: [{ dataSource: 'dailyRates', key: 'stay_date' }, ...tableCols],
  });

  return (
    <DataGrid
      headers={headers}
      rows={rows}
      brandCode={brandCode}
      colToggles={{}}
      dateSegment='day'
      monthOffset={0}
      snapshotDate={DateHelpers.today()}
      sortDir='asc'
      sortKey='stay_date'
      targetDate={{
        value: DateHelpers.today(),
      }}
      startDate={DateHelpers.today()}
      setSortDir={() => {}}
      setSortKey={() => {}}
    />
  );
};

export default RateDataTable;
