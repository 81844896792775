import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';

import { Button } from '@/components/ui/button';
import { DatePickerFormField } from './date-picker-form-field';
import { Input } from '@/components/ui/input';
import ReactToolTip from 'react-tooltip';
import { Textarea } from '@/components/ui/textarea';
import { eventSchema } from '@/features/events/schemas/event-schema';
import { useEventManagement } from '@/features/events/hooks/use-event-management';
import { useRef } from 'react';
import { z } from 'zod';

export const AddEventForm = () => {
  const { form, onSubmit, processing, rememberValues, setRememberValues } =
    useEventManagement();

  const startDateRef = useRef<string | null>(null);
  const endDateRef = useRef<string | null>(null);

  const handleError = (error: any) => {
    console.error(error);
  };

  // Adding option to create another event after submitting or keep form values
  const handleSubmit = (data: unknown) => {
    onSubmit(data as z.infer<typeof eventSchema>);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit, handleError)}
        className='space-y-4 mb-8'
      >
        <FormField
          control={form.control}
          name='name'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input
                  placeholder='Event name'
                  autoComplete='off'
                  {...field}
                  value={field.value ?? ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex space-x-4'>
          <DatePickerFormField
            name='start_date'
            label='Start Date'
            dateRef={startDateRef}
            form={form}
          />
          <DatePickerFormField
            name='end_date'
            label='End Date'
            minDate={form.watch('start_date')}
            dateRef={endDateRef}
            form={form}
          />
        </div>
        <FormField
          control={form.control}
          name='details'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Details</FormLabel>
              <FormControl>
                <Textarea
                  placeholder='Event details'
                  autoComplete='off'
                  {...field}
                  value={field.value ?? ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Checkbox to remember form values and Create Event button*/}
        <Button
          type='submit'
          className='relative flex items-center space-x-2'
          disabled={processing}
        >
          <span
            className='relative inline-flex items-center'
            data-tip='Remember Form Values'
          >
            <input
              id='remember-values'
              type='checkbox'
              name='remember-values'
              checked={rememberValues}
              onChange={() => {
                const newValue = !rememberValues;
                setRememberValues(newValue);
              }}
              className='h-4 w-4 rounded border-gray-300 cursor-pointer mr-2'
            />
          </span>
          <ReactToolTip />
          <span>{processing ? 'Processing...' : 'Create Event'}</span>
        </Button>
      </form>
    </Form>
  );
};
