import {
  Bold,
  Italic,
  List,
  ListOrdered,
  Underline as UnderlineIcon,
} from 'lucide-react';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { EditorContent, useEditor } from '@tiptap/react';
import { GenMeetingNotesPayload, MeetingNotesObject } from '@/core/ai/schemas';
import {
  UseMeetingNotesProps,
  useMeetingNotes,
} from '@/features/notes/hooks/use-notes-new';
import {
  extensions,
  handlePaste,
  loadFromLocalStorage,
  parseContent,
  saveToLocalStorage,
} from '@/lib/tiptap';
import { memo, useEffect, useState } from 'react';
import {
  transformGroupData,
  transformRmRecsData,
} from '@/features/reports/utils/transformers';

import { BtnGenerateNotes } from '@/features/notes/components/btn-generate-notes';
import { BtnInsertNotepad } from '@/features/notes/components/btn-insert-notepad';
import { BtnInsertNotes } from '@/features/notes/components/btn-insert-notes';
import { Button } from '@/components/ui/button';
import { Meeting } from '@/graphql/types';
import { ReportDataType } from '@/features/reports/context/report-data-context';
import { analyzeRateShopData } from '@/features/notes/utils/rate-shop-data';
import clsx from 'clsx';
import { toast } from '@/components/ui/use-toast';
import { useDebouncedCallback } from 'use-debounce';
import { useGenerateNotes } from '@/features/notes/hooks/use-generate-notes';
import { useIsShared } from '@/features/reports/hooks/use-report-location';

const AUTOSAVE_DELAY = 10000;

const convertAiContentToTiptapJson = (content: MeetingNotesObject) => {
  const doc: any = {
    type: 'doc',
    content: [],
  };

  // Add title as h2
  if (content.title) {
    doc.content.push({
      type: 'heading',
      attrs: { level: 3 },
      content: [{ type: 'text', text: content.title }],
    });
  }

  if (content.summary) {
    doc.content.push({
      type: 'paragraph',
      content: [{ type: 'text', text: content.summary }],
    });
  }

  if (content.lastNight) {
    doc.content.push({
      type: 'heading',
      attrs: { level: 4 },
      content: [{ type: 'text', text: content.lastNight.title }],
    });
    if (content.lastNight.summaryItems?.length) {
      const bulletList = {
        type: 'bulletList',
        content: content.lastNight.summaryItems.map((item) => ({
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [{ type: 'text', text: item }],
            },
          ],
        })),
      };
      doc.content.push(bulletList);
    }
  }

  if (content.rateShops) {
    doc.content.push({
      type: 'heading',
      attrs: { level: 4 },
      content: [{ type: 'text', text: content.rateShops.title }],
    });
    if (content.rateShops.summary) {
      doc.content.push({
        type: 'paragraph',
        content: [{ type: 'text', text: content.rateShops.summary }],
      });
    }
  }

  if (content.rmRecs) {
    doc.content.push({
      type: 'heading',
      attrs: { level: 4 },
      content: [{ type: 'text', text: content.rmRecs.title }],
    });
    if (content.rmRecs.summaryItems?.length) {
      const bulletList = {
        type: 'bulletList',
        content: content.rmRecs.summaryItems.map((item) => ({
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [{ type: 'text', text: item }],
            },
          ],
        })),
      };
      doc.content.push(bulletList);
    }
  }

  if (content.groups && content.groups.summaryItems?.length) {
    doc.content.push({
      type: 'heading',
      attrs: { level: 4 },
      content: [{ type: 'text', text: content.groups.title }],
    });
    if (content.groups.summaryItems?.length) {
      const bulletList = {
        type: 'bulletList',
        content: content.groups.summaryItems.map((item) => ({
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [{ type: 'text', text: item }],
            },
          ],
        })),
      };
      doc.content.push(bulletList);
    }
  }

  // Add notes as paragraphs
  if (content.notes) {
    doc.content.push({
      type: 'heading',
      attrs: { level: 4 },
      content: [{ type: 'text', text: 'General Notes' }],
    });
    doc.content.push({
      type: 'paragraph',
      content: [{ type: 'text', text: content.notes }],
    });
  }

  // Add action items as bullet list
  if (content.actionItems?.length) {
    doc.content.push({
      type: 'heading',
      attrs: { level: 4 },
      content: [{ type: 'text', text: 'For Follow Up' }],
    });
    const bulletList = {
      type: 'bulletList',
      content: content.actionItems.map((item) => ({
        type: 'listItem',
        content: [
          {
            type: 'paragraph',
            content: [{ type: 'text', text: item }],
          },
        ],
      })),
    };
    doc.content.push(bulletList);
  }

  return doc;
};

interface MeetingNotesProps extends UseMeetingNotesProps {
  parentName: string;
  reportData?: ReportDataType;
  loading?: boolean;
  setMeeting?: (meeting: Meeting) => void;
}

const MeetingNotes = memo(function MeetingNotes(props: MeetingNotesProps) {
  const {
    connection,
    data: aiNotes,
    error,
    status,
    setStatus,
  } = useGenerateNotes();
  const [isMounted, setIsMounted] = useState(true);
  const { isShared } = useIsShared();
  const { parentName, reportData, loading, ...rest } = props;
  const [isSaving, setIsSaving] = useState(false);
  const { handleSave, notes, notepad, prevNotes } = useMeetingNotes({
    ...rest,
  });

  const triggerAiGeneration = () => {
    if (connection) {
      const payload: GenMeetingNotesPayload = {
        action: 'genMeetingNotes',
        data: {
          brandCode: rest.brandCode,
          userId: rest.userId,
          lastNightData: reportData?.lastNightData,
          fiveDayRatesData: analyzeRateShopData(reportData?.fiveDayRatesData),
          rmRecsData: transformRmRecsData(reportData?.dataRmRecs),
          groupData: transformGroupData(reportData?.dataReport?.groupDaily),
        },
      };
      connection.send(JSON.stringify(payload));
      setStatus('Thinking...');
    } else {
      alert('Hello from Skynet!');
    }
  };

  const flexWidth = parentName === 'meeting-tools' ? 'max-w-[400px]' : 'w-full';

  const editor = useEditor({
    extensions,
    content: notes,
    editable: !isShared,
    autofocus: true,
    editorProps: {
      attributes: {
        class: `outline-none ${flexWidth} min-h-[5px] p-1 leading-extra-tight text-sm`,
      },
      handlePaste,
    },
  });

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
      if (editor) {
        editor.destroy();
      }
    };
  }, [editor]);

  useEffect(() => {
    if (aiNotes) {
      const { role, content, type } = aiNotes;
      if (role === 'assistant' && type === 'object' && content) {
        if (editor && content) {
          const tiptapJson = convertAiContentToTiptapJson(content);
          editor.commands.setContent(tiptapJson);
        }
      }
    }
  }, [aiNotes, editor]);

  const handleInsert = (source: 'notepad' | 'prevNotes' | 'aiNotes') => {
    const contentToInsert =
      source === 'notepad'
        ? notepad
        : source === 'prevNotes'
        ? prevNotes
        : (aiNotes as any);
    const jsonToInsert = parseContent(contentToInsert);

    if (editor) {
      if (editor.isEmpty) {
        editor.commands.setContent(jsonToInsert);
      } else {
        editor.commands.insertContent(jsonToInsert);
        // Insert at cursor position
        // editor.chain().focus().insertContent(jsonToInsert.content).run();
      }
    }
  };

  const saveNotes = async (content: object) => {
    if (!isMounted) return;
    setIsSaving(true);
    try {
      await handleSave(JSON.stringify(content));
      saveToLocalStorage({ brandCode: rest.brandCode, content });
      if (isMounted) {
        toast({
          title: 'Notes saved',
          description: 'Your meeting notes have been saved successfully.',
        });
      }
    } catch (error) {
      console.error('Failed to save notes:', error);
      if (isMounted) {
        toast({
          title: 'Error saving notes',
          description:
            "There was a problem saving your notes. They've been stored locally.",
          variant: 'destructive',
        });
      }
    } finally {
      if (isMounted) setIsSaving(false);
    }
  };

  const debouncedSave = useDebouncedCallback(async (content: object) => {
    await saveNotes(content);
  }, AUTOSAVE_DELAY);

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  useEffect(() => {
    if (editor && props.brandCode) {
      const savedContent = loadFromLocalStorage({ brandCode: rest.brandCode });
      const contentToSet = parseContent(notes || savedContent);

      if (
        editor.isEmpty ||
        JSON.stringify(editor.getJSON()) !== JSON.stringify(contentToSet)
      ) {
        editor.commands.setContent(contentToSet || null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, notes, props.brandCode]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (
        editor &&
        !editor.isEmpty &&
        JSON.stringify(editor.getJSON()) !== JSON.stringify(parseContent(notes))
      ) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, notes]);

  useEffect(() => {
    if (editor) {
      editor.on('update', ({ editor }) => {
        const content = editor.getJSON();
        debouncedSave(content);
      });
    }
  }, [editor, debouncedSave]);

  const handleManualSave = async () => {
    if (editor) {
      const content = editor.getJSON();
      await saveNotes(content);
    }
  };

  return (
    <Card className='w-full mx-auto'>
      <CardContent className='p-2'>
        <div className='p-2'>
          {!isShared && (
            <div className='flex gap-2 flex-wrap'>
              <Button
                variant='outline'
                size='icon-sm'
                onClick={() => editor?.chain().focus().toggleBold().run()}
                className={editor?.isActive('bold') ? 'bg-muted' : ''}
              >
                <Bold className='h-3 w-3' />
              </Button>
              <Button
                variant='outline'
                size='icon-sm'
                onClick={() => editor?.chain().focus().toggleItalic().run()}
                className={editor?.isActive('italic') ? 'bg-muted' : ''}
              >
                <Italic className='h-3 w-3' />
              </Button>
              <Button
                variant='outline'
                size='icon-sm'
                onClick={() => editor?.chain().focus().toggleUnderline().run()}
                className={editor?.isActive('underline') ? 'bg-muted' : ''}
              >
                <UnderlineIcon className='h-3 w-3' />
              </Button>
              <Button
                variant='outline'
                size='icon-sm'
                onClick={() => editor?.chain().focus().toggleBulletList().run()}
                className={editor?.isActive('bulletList') ? 'bg-muted' : ''}
              >
                <List className='h-3 w-3' />
              </Button>
              <Button
                variant='outline'
                size='icon-sm'
                onClick={() =>
                  editor?.chain().focus().toggleOrderedList().run()
                }
                className={editor?.isActive('orderedList') ? 'bg-muted' : ''}
              >
                <ListOrdered className='h-3 w-3' />
              </Button>
              <BtnInsertNotes handleInsert={() => handleInsert('prevNotes')} />
              <BtnInsertNotepad handleInsert={() => handleInsert('notepad')} />
              <BtnGenerateNotes
                connection={connection}
                dataReady={!loading}
                triggerGeneration={triggerAiGeneration}
              />
              {status && (
                <span className='text-sm animate-pulse'>{status}</span>
              )}
            </div>
          )}
        </div>
        <div className='w-full border rounded-sm mx-1'>
          <EditorContent
            editor={editor}
            className='prose prose-sm max-w-none w-full'
          />
        </div>
        <div className='flex justify-start px-1 py-1'>
          <Button
            className={clsx(isShared && 'hidden')}
            onClick={handleManualSave}
            disabled={isSaving || isShared}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </CardContent>
      {error && <CardFooter>{error?.message}</CardFooter>}
    </Card>
  );
});

export default MeetingNotes;
