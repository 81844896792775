import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

import { ArrowUpDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { format } from 'date-fns';
import { today } from '@/helpers/dateHelpers';
import { useEmailsByDateAndBrandCodeQuery } from '../gql/_gen_/email-messages.gql';
import { useState } from 'react';

type EmailStatusTableProps = {
  brandCode?: string | null;
  meetingDate?: string | null;
};

export function EmailStatusTable({
  brandCode,
  meetingDate,
}: EmailStatusTableProps) {
  const [sortBy, setSortBy] = useState<'date' | 'status'>('date');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const { data, loading } = useEmailsByDateAndBrandCodeQuery({
    skip: !brandCode,
    variables: {
      input: {
        brandCode: brandCode!,
        meetingDate: meetingDate ?? today(),
      },
    },
  });

  const sortedData = [...(data?.emailsByDateAndBrandCode ?? [])].sort(
    (a, b) => {
      const aValue =
        sortBy === 'date'
          ? new Date(a?.createdAt || new Date()).getTime()
          : a?.status ?? '';
      const bValue =
        sortBy === 'date'
          ? new Date(b?.createdAt || new Date()).getTime()
          : b?.status ?? '';
      return sortOrder === 'asc'
        ? aValue > bValue
          ? 1
          : -1
        : aValue < bValue
        ? 1
        : -1;
    }
  );

  const toggleSort = (column: 'date' | 'status') => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('desc');
    }
  };

  return (
    <Card className='w-full'>
      <div className='max-h-[300px] overflow-auto'>
        <Table>
          <TableHeader className='sticky top-0 bg-background'>
            <TableRow>
              <TableHead className='w-[100px]'>Brand Code</TableHead>
              <TableHead className='w-[100px]'>
                <Button
                  variant='ghost'
                  onClick={() => toggleSort('status')}
                  className='h-8 px-2 hover:bg-muted'
                >
                  Status
                  <ArrowUpDown className='ml-2 h-4 w-4' />
                </Button>
              </TableHead>
              <TableHead className='w-[180px]'>
                <Button
                  variant='ghost'
                  onClick={() => toggleSort('date')}
                  className='h-8 px-2 hover:bg-muted'
                >
                  Created
                  <ArrowUpDown className='ml-2 h-4 w-4' />
                </Button>
              </TableHead>
              <TableHead className='w-[180px]'>
                <Button
                  variant='ghost'
                  onClick={() => toggleSort('date')}
                  className='h-8 px-2 hover:bg-muted'
                >
                  Updated
                  <ArrowUpDown className='ml-2 h-4 w-4' />
                </Button>
              </TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Meeting ID</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading ? (
              <TableRow className='text-center'>
                <TableCell colSpan={6}>Loading...</TableCell>
              </TableRow>
            ) : null}
            {!loading && sortedData.length === 0 ? (
              <TableRow className='text-center'>
                <TableCell colSpan={6}>--- No Data For Today ---</TableCell>
              </TableRow>
            ) : null}
            {sortedData.map((email) => (
              <TableRow key={email?.id}>
                <TableCell>{email?.brandCode}</TableCell>
                <TableCell>
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${
                      email?.status === 'DELIVERED'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}
                  >
                    {(email?.status ?? 'PENDING').toLowerCase()}
                  </span>
                </TableCell>
                <TableCell className='font-medium'>
                  {format(
                    new Date(email?.createdAt ?? new Date()),
                    'MMM d, yyyy HH:mm'
                  )}
                </TableCell>
                <TableCell className='font-medium'>
                  {format(
                    new Date(email?.updatedAt ?? new Date()),
                    'MMM d, yyyy HH:mm'
                  )}
                </TableCell>
                <TableCell className='truncate max-w-[200px]'>
                  {email?.userEmail}
                </TableCell>
                <TableCell className='truncate max-w-[200px]'>
                  <a
                    className='text-sky-800'
                    href={`/shared_report/${email?.meetingId}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {email?.meetingId}
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
}
