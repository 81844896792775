import { ArrowUpDown, CalendarIcon, Pencil, Trash } from 'lucide-react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { addMonths, format, isAfter, isBefore, parse } from 'date-fns';
import { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { ColumnDef } from '@tanstack/react-table';
import { Event } from '@/graphql/types';
import { Input } from '@/components/ui/input';
import { UseFormReturn } from 'react-hook-form';

interface EditableCellProps<TData> {
  value: string;
  row: { index: number; original: TData };
  column: { id: string };
  table: {
    options: {
      meta: {
        updateData: (rowIndex: number, columnId: string, value: string) => void;
      };
    };
  };
}

export const EditableCell = <TData,>({
  value,
  row,
  column,
  table,
}: EditableCellProps<TData>) => {
  const [editedValue, setEditedValue] = useState(value);
  const { updateData } = table.options.meta;

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  const onBlur = () => {
    updateData(row.index, column.id, editedValue);
  };

  return (
    <Input
      value={editedValue}
      onChange={(e) => setEditedValue(e.target.value)}
      onBlur={onBlur}
    />
  );
};

export const getEventColumns = (
  editingId: string | null,
  editForm: UseFormReturn<Event>,
  startEditing: (eventId: Event['id']) => void,
  saveEdit: (data: Event) => void,
  cancelEditing: () => void,
  deleteEvent: (eventId: Event['id']) => void,
  processing: boolean
): ColumnDef<Event>[] => [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return (
        <Button
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Name
          <ArrowUpDown className='ml-2 h-4 w-4' />
        </Button>
      );
    },
    cell: ({ row }) => {
      const event = row.original;
      if (editingId === event.id) {
        return (
          <EditableCell<Event>
            value={editForm.getValues('name') || ''}
            row={row}
            column={{ id: 'name' }}
            table={{
              options: {
                meta: {
                  updateData: (_rowIndex, _columnId, value) => {
                    editForm.setValue('name', value);
                  },
                },
              },
            }}
          />
        );
      }
      return event.name;
    },
  },
  {
    accessorKey: 'start_date',
    header: ({ column }) => {
      return (
        <Button
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Start Date
          <ArrowUpDown className='ml-2 h-4 w-4' />
        </Button>
      );
    },
    cell: ({ row }) => {
      const event = row.original;
      if (editingId === event.id) {
        return (
          <Form {...editForm}>
            <FormField
              control={editForm.control}
              name='start_date'
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant='outline'
                          className={`w-[240px] pl-3 text-left font-normal ${
                            !field.value && 'text-muted-foreground'
                          }`}
                        >
                          {field.value ? (
                            format(
                              parse(field.value, 'yyyy-MM-dd', new Date()),
                              'PPP'
                            )
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className='w-auto p-0' align='start'>
                      <Calendar
                        mode='single'
                        selected={
                          field.value
                            ? parse(field.value, 'yyyy-MM-dd', new Date())
                            : undefined
                        }
                        onSelect={(date) => {
                          field.onChange(
                            date ? format(date, 'yyyy-MM-dd') : ''
                          );
                        }}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          </Form>
        );
      }
      return format(parse(event.start_date, 'yyyy-MM-dd', new Date()), 'PPP');
    },
  },
  {
    accessorKey: 'end_date',
    header: ({ column }) => {
      return (
        <Button
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          End Date
          <ArrowUpDown className='ml-2 h-4 w-4' />
        </Button>
      );
    },
    cell: ({ row }) => {
      const event = row.original;
      if (editingId === event.id) {
        return (
          <Form {...editForm}>
            <FormField
              control={editForm.control}
              name='end_date'
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant='outline'
                          className={`w-[240px] pl-3 text-left font-normal ${
                            !field.value && 'text-muted-foreground'
                          }`}
                        >
                          {field.value ? (
                            format(
                              parse(field.value, 'yyyy-MM-dd', new Date()),
                              'PPP'
                            )
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className='w-auto p-0' align='start'>
                      <Calendar
                        mode='single'
                        selected={
                          field.value
                            ? parse(field.value, 'yyyy-MM-dd', new Date())
                            : undefined
                        }
                        onSelect={(date) => {
                          field.onChange(
                            date ? format(date, 'yyyy-MM-dd') : ''
                          );
                        }}
                        disabled={(date) => {
                          const startDate = editForm.getValues('start_date');
                          if (startDate) {
                            const oneMonthLater = addMonths(
                              parse(startDate, 'yyyy-MM-dd', new Date()),
                              1
                            );
                            return (
                              isBefore(
                                date,
                                parse(startDate, 'yyyy-MM-dd', new Date())
                              ) || isAfter(date, oneMonthLater)
                            );
                          }
                          return startDate
                            ? date < parse(startDate, 'yyyy-MM-dd', new Date())
                            : date < new Date();
                        }}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          </Form>
        );
      }
      return format(parse(event.end_date, 'yyyy-MM-dd', new Date()), 'PPP');
    },
  },
  {
    accessorKey: 'details',
    header: ({ column }) => {
      return (
        <Button
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Details
          <ArrowUpDown className='ml-2 h-4 w-4' />
        </Button>
      );
    },
    cell: ({ row }) => {
      const event = row.original;
      if (editingId === event.id) {
        return (
          <EditableCell<Event>
            value={editForm.getValues('details') || ''}
            row={row}
            column={{ id: 'details' }}
            table={{
              options: {
                meta: {
                  updateData: (_rowIndex, _columnId, value) => {
                    editForm.setValue('details', value);
                  },
                },
              },
            }}
          />
        );
      }
      return event.details;
    },
  },
  {
    accessorKey: 'created_by',
    header: ({ column }) => {
      return (
        <Button
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Created By
          <ArrowUpDown className='ml-2 h-4 w-4' />
        </Button>
      );
    },
    cell: ({ row }) => {
      const event = row.original;
      return event.created_by?.name;
    },
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return (
        <Button
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Created
          <ArrowUpDown className='ml-2 h-4 w-4' />
        </Button>
      );
    },
    cell: ({ row }) => {
      const event = row.original;
      return event.created_at;
    },
  },
  {
    accessorKey: 'updated_at',
    header: ({ column }) => {
      return (
        <Button
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Updated
          <ArrowUpDown className='ml-2 h-4 w-4' />
        </Button>
      );
    },
    cell: ({ row }) => {
      const event = row.original;
      return event.updated_at;
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      const event = row.original;
      if (editingId === event.id) {
        return (
          <>
            <Button
              disabled={processing}
              onClick={() => {
                editForm.handleSubmit(saveEdit)();
              }}
              className='mr-2'
            >
              {processing ? 'Saving...' : 'Save'}
            </Button>
            <Button onClick={cancelEditing} variant='outline'>
              Cancel
            </Button>
          </>
        );
      }
      return (
        <>
          <Button
            onClick={() => startEditing(event.id)}
            variant='ghost'
            size='icon'
            className='mr-2'
          >
            <Pencil className='h-4 w-4' />
          </Button>
          <Button
            onClick={() => deleteEvent(event.id)}
            variant='ghost'
            size='icon'
          >
            <Trash className='h-4 w-4' />
          </Button>
        </>
      );
    },
  },
];
