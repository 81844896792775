import { MenuItem, menuItems } from './menu-items';
import { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import { ExpandableMeetingTools } from './expandable-meeting-tools';
import { FaTools } from 'react-icons/fa';
import MeetingTools from '../meetings/components/meeting-tools';
import MeetingToolsNew from '../meetings/components/meeting-tools-new';
import NavButton from './components/nav-button';
import NavDropdown from './components/nav-dropdown';
import NavLogo from './components/nav-logo';
import { NotepadButton } from './components/notepad-button';
import { NotepadButton as NotepadButtonNew } from './components/notepad-button-new';
import ReactTooltip from 'react-tooltip';
import UserMenu from './components/user-menu';
import { useFlags } from '@/context/feature-flags';
import { useHotel } from '@/context/hotelContext';
import { useIsShared } from '../reports/hooks/use-report-location';
import { useLocation } from 'react-router-dom';
import { useUser } from '@/context/userContext';

function Navbar() {
  const [expandTools, setExpandTools] = useState(false);
  const location = useLocation();
  const { oktaUser, user } = useUser();
  const { hotel } = useHotel();
  const { isShared } = useIsShared();
  const { flags } = useFlags();

  const isManager = flags?.['admin-menu'];
  const showNewNotes = flags?.['meeting-notes-upgrade'];
  const meetingPanelUpgrade = flags?.['meeting-panel-upgrade'];

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const isActive = (menuKey: string) => {
    return location.pathname.includes(menuKey);
  };

  const filterAdmin = (item: MenuItem) => {
    if (item.adminOnly && !isManager) return false;
    return true;
  };

  return (
    <section>
      <nav className='relative'>
        <div className='p-3 flex items-center bg-blue-900'>
          <NavLogo />
          {!isShared && (
            <ul className='hidden md:flex'>
              {menuItems.filter(filterAdmin).map((item) => {
                const btnProps = { ...item, isActive: isActive(item.key) };
                if (item.tabItems && item.tabItems.length > 0) {
                  return <NavDropdown {...btnProps} />;
                } else {
                  return <NavButton {...btnProps} />;
                }
              })}
            </ul>
          )}
          <div className='hidden md:block md:justify-end md:items-center md:space-x-6 mr-6 ml-auto'>
            <div className='flex items-center'>
              {!isShared && oktaUser && oktaUser.email && (
                <>
                  {showNewNotes ? (
                    meetingPanelUpgrade ? (
                      <Button
                        type='button'
                        variant='ghost'
                        className='p-2 text-white'
                        onClick={() => setExpandTools(!expandTools)}
                        data-tip='Meeting Tools'
                      >
                        <FaTools className='h-4 w-4' />
                      </Button>
                    ) : (
                      <>
                        <NotepadButtonNew
                          brandCode={hotel?.brand_code}
                          userId={user?.id}
                        />
                        <MeetingToolsNew />
                      </>
                    )
                  ) : (
                    <>
                      <NotepadButton
                        brandCode={hotel?.brand_code}
                        userId={user?.id}
                      />
                      <MeetingTools />
                    </>
                  )}

                  <UserMenu
                    email={oktaUser.email}
                    name={oktaUser.name}
                    role={oktaUser.userRole}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
      <ExpandableMeetingTools
        isExpanded={expandTools}
        setIsExpanded={setExpandTools}
      />
    </section>
  );
}

export default Navbar;
