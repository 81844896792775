import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { useEffect, useState } from 'react';

import { X } from 'lucide-react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export const VoteAlert = () => {
  const location = useLocation();
  const { vote } = queryString.parse(location.search);
  const [isVisible, setIsVisible] = useState(true);
  const showVoteSuccess = vote === 'success' && isVisible;
  const showVoteError = vote === 'error' && isVisible;

  useEffect(() => {
    if (isVisible && (vote === 'success' || vote === 'error')) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, vote]);

  if (showVoteSuccess) {
    return (
      <div className='flex justify-center text-center'>
        <Alert className='m-4 w-4/5 relative' variant='positive'>
          <button
            onClick={() => setIsVisible(false)}
            className='absolute right-2 top-2 p-1 rounded-sm opacity-70 hover:opacity-100'
          >
            <X className='h-4 w-4' />
          </button>
          <AlertTitle>Thank you!</AlertTitle>
          <AlertDescription>
            We appreciate your feedback and will use it to improve our service.
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  if (showVoteError) {
    return (
      <div className='flex justify-center text-center'>
        <Alert className='m-4 w-4/5 relative' variant='destructive'>
          <button
            onClick={() => setIsVisible(false)}
            className='absolute right-2 top-2 p-1 rounded-sm opacity-70 hover:opacity-100'
          >
            <X className='h-4 w-4' />
          </button>
          <AlertTitle>There was an issue</AlertTitle>
          <AlertDescription>
            We are sorry but there was an issue recording your feedback. Please
            let your revenue manager know so we can still record your feedback.
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  return null;
};
