import { useEffect, useState } from 'react';

import { GeneratedObject } from '../zod';
import { useFlags } from '@/context/feature-flags';
import { useOktaAuth } from '@okta/okta-react';

export function useGenerateNotes() {
  const { flags } = useFlags();
  const aiNotesFlag = flags?.['ai-notes'];
  const { authState } = useOktaAuth();
  const [connection, setConnection] = useState<WebSocket | null>(null);
  const [data, setData] = useState<GeneratedObject>();

  const [error, setError] = useState<Error | null>(null);
  const [status, setStatus] = useState<string | null>(null);

  useEffect(() => {
    let ws: WebSocket | null = null;
    let mounted = true;

    const connect = async () => {
      try {
        const url = process.env.REACT_APP_WS_API_URL;

        if (!url) {
          throw new Error('No Web Socket API URL found');
        }

        // Least worst way to "smuggle" auth tokens to websockets:
        // https://stackoverflow.com/a/77060459
        ws = new WebSocket(url, [
          `token.${authState?.accessToken?.accessToken}`,
        ]);

        ws.onopen = () => {
          console.log('Connected to websocket');
          setConnection(ws);
        };

        ws.onmessage = (event) => {
          try {
            const eventData = JSON.parse(event.data);

            if (eventData) {
              switch (eventData.type) {
                case 'analyze':
                  setStatus('Analyzing data...');
                  break;
                case 'end':
                  setStatus(null);
                  break;
                case 'object':
                  if (mounted) {
                    setData(eventData);
                    setError(null);
                    setStatus(null);
                  }
                  break;
                default:
                  setStatus(null);
                  break;
              }
            }
          } catch (err) {
            console.error('Error processing message:', err);

            if (mounted && err instanceof Error) {
              setError(err);
              setStatus(null);
            }
          }
        };

        ws.onerror = (event) => {
          console.error('WebSocket error:', event);

          if (mounted) {
            setError(new Error('WebSocket connection error'));
          }
        };

        ws.onclose = () => {
          setConnection(null);
          console.log('WebSocket connection closed');
          // Attempt to reconnect after a delay
          if (mounted) {
            setTimeout(connect, 5000);
          }
        };
      } catch (err) {
        if (mounted && err instanceof Error) {
          setError(err);
        }
      }
    };

    connect();

    return () => {
      mounted = false;
      if (ws) {
        ws.close();
      }
    };
  }, [authState?.accessToken?.accessToken]);

  if (!aiNotesFlag) return { connection: null, data: undefined, error: null };

  return { connection, data, error, status, setStatus };
}
