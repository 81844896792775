import { CellObject } from '../features/overview-table/hooks/use-table';
import Paginate from './Paginate';
import { clsx } from 'clsx';
import { useState } from 'react';

type TableProps = {
  headers: any[];
  rows: any[][];
  customCellAlignment?: string;
  noWrap?: boolean;
  perPage?: number;
  renderer?: (cell: CellObject) => JSX.Element;
  variant?: 'default' | 'compact';
};

export default function Table(props: TableProps) {
  const perPage = props.perPage || 9;
  const {
    customCellAlignment,
    headers,
    noWrap = false,
    renderer,
    rows,
    variant = 'default',
  } = props;
  const totalPages = Math.ceil(rows.length / perPage);
  const [pageRange, setPageRange] = useState([0, totalPages]);

  const next = () => {
    if (pageRange[0] + 1 <= totalPages) {
      const last =
        pageRange[0] + 1 > totalPages ? totalPages : pageRange[0] + 1;
      setPageRange([last, pageRange[1]]);
    }
  };

  const previous = () => {
    if (pageRange[0] - 1 >= 0) {
      const first = pageRange[0] - 1 < 0 ? 0 : pageRange[0] - 1;
      setPageRange([first, pageRange[1]]);
    }
  };

  return (
    <div className='flex flex-col text-center'>
      <div
        className={clsx(
          variant === 'default'
            ? '-my-2 sm:-mx-6 lg:-mx-8'
            : '-my-1 sm:-mx-3 lg:-mx-4',
          'overflow-x-auto'
        )}
      >
        <div
          className={clsx(
            variant === 'default'
              ? 'py-2 sm:px-6 lg:px-8'
              : 'py-1 sm:px-3 lg:px-4',
            'align-middle inline-block min-w-full'
          )}
        >
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  {headers.map((header, i) => {
                    const tooltip = rows[0]?.[i]?.meta?.tooltip;
                    return (
                      <th
                        scope='col'
                        data-tip={tooltip}
                        key={`header-${i}`}
                        className={clsx(
                          noWrap && 'whitespace-nowrap',
                          customCellAlignment,
                          variant === 'default'
                            ? 'px-6 py-3 font-medium tracking-wider'
                            : 'px-3 py-1',
                          'text-gray-500 text-xs uppercase'
                        )}
                      >
                        {header}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {rows
                  .slice(
                    pageRange[0] * perPage,
                    pageRange[0] * perPage + perPage
                  )
                  .map((row, r) => (
                    <tr key={`row-${r}`}>
                      {row.map((cell, c) => {
                        let cellValue = cell;
                        let extraClasses = 'text-gray-900';
                        if (typeof cell === 'object' && cell !== null) {
                          cellValue = cell.value;
                          cell.extraClasses &&
                            (extraClasses = cell.extraClasses);
                        }
                        return (
                          <td
                            key={`cell-${r}-${c}`}
                            className={clsx(
                              variant === 'default'
                                ? `px-6 py-3 text-sm font-medium`
                                : 'px-3 py-1 text-xs',
                              `whitespace-nowrap ${extraClasses}`,
                              customCellAlignment
                            )}
                          >
                            {renderer ? renderer(cell) : cellValue}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>
            {perPage && totalPages && totalPages > 1 ? (
              <Paginate
                first={pageRange[0]}
                last={pageRange[1]}
                total={rows.length}
                next={next}
                previous={previous}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
