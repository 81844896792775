import { AiFillSave } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { LoadingTooltip } from './loading-tooltip';
import { useMeeting } from '../../meetings/context/meeting-context';
import { useReportData } from '../context/report-data-context';
import { useTrackMeetingEvent } from '../../tracking/hooks/use-track-event';

type BtnSaveMeetingProps = {
  btnClass: string;
  btnIconClass?: string;
  loading: Array<string>;
};

const BtnSaveMeeting = ({
  btnClass,
  btnIconClass = 'h-5 w-5',
  loading,
}: BtnSaveMeetingProps) => {
  const { meeting, meetingTools, saveMeeting, saveResults } = useMeeting();
  const { data } = useReportData();
  const track = useTrackMeetingEvent({ meeting, page: 'btn_save_meeting' });

  const handleClick = () => {
    saveMeeting({ data, meetingTools });
    track('save_meeting', 'save_meeting_with_data');
  };

  const { loading: loadingSave } = saveResults;

  const BtnIcon = loadingSave || loading.length ? FaSpinner : AiFillSave;

  const iconClass = loading.length
    ? `${btnIconClass} animate-spin`
    : btnIconClass;

  return (
    <>
      <span
        onClick={() => (loading.length ? null : handleClick())}
        className={`${btnClass} text-green-500 ${
          loading.length
            ? 'opacity-50 text-blue-500'
            : loadingSave
            ? 'animate-spin'
            : 'cursor-pointer'
        }`}
        data-tip
        data-for='loading-data'
      >
        <BtnIcon className={iconClass} aria-hidden='true' />
      </span>
      <LoadingTooltip loading={loading} buttonType='Save' />
    </>
  );
};

export default BtnSaveMeeting;
