import { Maybe, Meeting, MeetingService, RmData } from '../../../graphql/types';
import { useEffect, useState } from 'react';

import { DateHelpers } from '../../../helpers/dateHelpers';
import { State as HotelState } from '../../../context/hotelContext';
import { ReportDataType } from '../../reports/context/report-data-context';
import { State as UserState } from '../../../context/userContext';
import axios from 'axios';
import { processTable } from '../../overview-table/utils/table-processor';
import { tableData } from '@/reports/dataLastNightTable';
import { useShareUrlLazyQuery } from '../gql/_gen_/meeting.gql';

interface UseStoredDataProps {
  bucket?: Maybe<string>;
  key?: Maybe<string>;
  meeting?: Maybe<Meeting>;
}

type StoredData = {
  data: ReportDataType;
  hotel: HotelState;
  meetingTools: MeetingService;
  user: UserState;
};

export const useStoredData = ({ bucket, key, meeting }: UseStoredDataProps) => {
  const [data, setReportData] = useState<StoredData>();

  const [fetching, setFetching] = useState(false);

  const [getShareUrl] = useShareUrlLazyQuery();

  const getReportData = async () => {
    if (!bucket || !key) return;
    setFetching(true);

    try {
      const url = await getShareUrl({ variables: { bucket, key } });
      if (url?.data?.shareUrl) {
        const response = (await axios.get(url.data.shareUrl)) as {
          data: StoredData;
        };

        // Generate lastNightData if it doesn't exist
        if (!response.data.data.lastNightData) {
          const tableDates = DateHelpers.lastNightTableDates(
            meeting?.meetingDate || DateHelpers.today()
          );

          response.data.data.lastNightData = tableData(
            DateHelpers.subtract(
              meeting?.meetingDate || DateHelpers.today(),
              'days',
              1
            ),
            response.data.data.dataReport?.rmData?.filter(
              (rm): rm is RmData =>
                rm !== null &&
                DateHelpers.betweenOrEqual(
                  rm?.stay_date,
                  tableDates.mtdStart,
                  tableDates.mtdEnd
                )
            ),
            response.data.data.dataReport?.rmData?.filter(
              (rm): rm is RmData =>
                rm !== null &&
                DateHelpers.betweenOrEqual(
                  rm?.stay_date_ly,
                  tableDates.mtdStartLy,
                  tableDates.mtdEndLy
                )
            )
          );
        }

        // Generate fiveDayRatesData if it doesn't exist
        if (!response.data.data.fiveDayRatesData) {
          const tableCols = response.data.data.dataRateShop?.compSet?.length
            ? response.data.data.dataRateShop.compSet.filter(
                (comp: any) => comp?.key !== 'rates.0'
              )
            : [];

          const { headers, rows } = processTable({
            customHeaders: null,
            data: {
              dailyRates: response.data.data.dataRateShop?.dailyRates,
              compSet: response.data.data.dataRateShop?.compSet,
              rmData: response.data.data.dataReport?.rmData,
            },
            dateSegment: 'day',
            includeSummary: false,
            startDate: meeting?.meetingDate || DateHelpers.today(),
            endDate: DateHelpers.addDays(
              meeting?.meetingDate || DateHelpers.today(),
              4
            ),
            detailCols: [
              { dataSource: 'rmData', key: 'dow' },
              { dataSource: 'dailyRates', key: 'stay_date' },
              { dataSource: 'rmData', key: 'occ' },
              { dataSource: 'rmData', key: 'ooo' },
              {
                dataSource: 'rmData',
                key: 'delta_7day',
                tooltip: '7-day delta (change in rooms committed)',
              },
              ...tableCols,
            ],
          });

          response.data.data.fiveDayRatesData = {
            headers: headers.map((h) => h.title),
            rows: rows.map((row) => row.map((cell) => cell)),
          };
        }

        setReportData(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (bucket && key) {
      getReportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucket, key, meeting]);

  return { data, fetching };
};
