import * as Types from '@/graphql/types';

import { GroupDataSchema, RmRecsDataSchema } from '../schema';
import { addDays, today } from '@/helpers/dateHelpers';

import { RmRecsWidgetQuery } from '../gql/_gen_/rm-recs-widget.gql';
import dayjs from 'dayjs';

/**
 * Transforms RmRecsWidgetQuery data to match the expected schema structure
 * for the AI meeting notes generation
 *
 * @param rmRecsData - The RM Recs data from GraphQL query
 * @returns Transformed data matching the expected schema or undefined if no data
 */
export const transformRmRecsData = (rmRecsData?: RmRecsWidgetQuery) => {
  if (!rmRecsData || !rmRecsData.hotelRmRecs) {
    return [];
  }

  const validItems = rmRecsData.hotelRmRecs
    .map((item) => {
      const result = RmRecsDataSchema.safeParse(item);
      return result.success ? result.data : null;
    })
    .filter((item) => item !== null);

  return validItems || [];
};

/**
 * Transforms GroupDataQuery data to match the expected schema structure
 * for the AI meeting notes generation
 *
 * @param groupData - The Group data from GraphQL query
 * @returns Transformed data matching the expected schema or undefined if no data
 */
export const transformGroupData = (groupData: Types.Query['groupDaily']) => {
  if (!groupData) {
    return [];
  }

  const validItems = groupData
    .map((item) => {
      const result = GroupDataSchema.safeParse(item);
      return result.success ? result.data : null;
    })
    .filter((item) => item !== null);

  const tenDaysOut = addDays(today(), 10);
  const filteredGroups = validItems.filter((item) => {
    // Use isAfter with negation instead of isSameOrBefore
    return !dayjs(item.stay_date).isAfter(tenDaysOut);
  });

  // Filter out details with no rooms remaining
  const groupsWithRooms = filteredGroups.map((group) => {
    return {
      ...group,
      details: group.details?.filter(
        (detail) => detail.group_rooms_remaining > 0
      ),
    };
  });

  return groupsWithRooms || [];
};
