import { CgCalendarToday, CgLivePhoto } from 'react-icons/cg';
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaGlasses,
} from 'react-icons/fa';
import { HiOutlineUserGroup, HiStatusOnline } from 'react-icons/hi';
import {
  MdBedroomParent,
  MdFreeCancellation,
  MdOutlineRateReview,
  MdSensorOccupied,
} from 'react-icons/md';
import { dayDiff, scrollDates } from '../../../helpers/dateHelpers';

import { BsBoxArrowInUpRight } from 'react-icons/bs';
import BtnForecastTools from '../../../components/toolbar/BtnForecastTools';
import BtnResetRecs from '../../../components/toolbar/BtnResetRecs';
import BtnToday from '../../../components/toolbar/BtnToday';
import BtnToolbarToggle from '../../../components/toolbar/BtnToolbarToggle';
import DateRangeSelector from './DateRangeSelector';
import { GiArchiveResearch } from 'react-icons/gi';
import { IoFootstepsSharp } from 'react-icons/io5';
import { PiPercentFill } from 'react-icons/pi';
import { TiWeatherPartlySunny } from 'react-icons/ti';
import { TrackingInputType } from '../../../types/Tracking';
import { useDashboard } from '../../../context/dashboardContext';
import { useMemo } from 'react';
import { useTracking } from 'react-tracking';
import { useUser } from '../../../context/userContext';

type ToolbarProps = {
  isLoading: boolean;
};

const BTN_ICON_CLASS = 'h-3 w-3';

const Toolbar = ({ isLoading }: ToolbarProps) => {
  const {
    daysOut,
    hotel,
    hotelSettings,
    options,
    setOptions,
    setTableSettings,
    setTargetDate,
  } = useDashboard();
  const { user, oktaUser } = useUser();

  const { dateSegment, endDate, snapshotDate, startDate, yearsCompare } =
    options;

  const filters = useMemo(() => {
    return {
      hotelId: hotel?.hotel_id || hotel?.brand_code,
      startDate,
      endDate,
    };
  }, [hotel, startDate, endDate]);

  const trackingData = (action: string): TrackingInputType | undefined => {
    if (user && hotel && oktaUser) {
      return {
        action,
        brandCode: hotel.brand_code,
        dateSegment,
        endDate,
        page: 'dashboard_overview',
        snapshotDate,
        startDate,
        userEmail: user.email as string,
        userName: oktaUser.name,
        userRole: oktaUser.userRole,
        yearsCompare,
      };
    }
  };

  const { trackEvent } = useTracking(trackingData('page_mount'), {
    dispatchOnMount: true,
  });

  const handleDateRangeChange = (startDate: string, endDate: string) => {
    trackEvent({
      ...trackingData('date_range_change'),
      startDate,
      endDate,
    });
    setOptions({ startDate, endDate });
  };

  /**
   * Handles the tracking of the user interaction, along with setting the new
   * start and end dates to "scroll" or page up/down the dashboard.
   * @param direction Either 'prev' or 'next'. Impacts the direction of the scroll.
   */
  const handleScrollDates = (direction: 'next' | 'prev') => {
    const newDates = scrollDates({
      startDate,
      endDate,
      direction,
      days: dayDiff(startDate, endDate),
    });
    trackEvent({
      ...trackingData('scoll_dates'),
      ...newDates,
    });
    setOptions(newDates);
  };

  const handleToggleSuperscript = () => {
    setTableSettings({
      brandCode: hotel?.brand_code,
      type: 'SUPERSCRIPT',
      key: 'forecasts',
      payload: {
        showSuperscript: !hotelSettings?.forecasts.showSuperscript || false,
      },
    });
  };

  const btnIconClass = BTN_ICON_CLASS;
  const baseBtnClass =
    'relative inline-flex items-center px-2 py-2 border text-sm font-medium focus:z-10';
  const beginBtnClass = `${baseBtnClass} rounded-l-md`;
  const endBtnClass = `${baseBtnClass} -ml-px rounded-r-md`;
  const middleBtnClass = `${baseBtnClass} -ml-px`;
  const toggleBtnClass = `${baseBtnClass} -ml-px rounded-l-md rounded-r-md`;

  const buttonProps = [
    {
      key: 'forecasts',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.forecasts.visible,
      toolbar: hotelSettings?.forecasts.toolbar,
      btnIcon: FaGlasses,
      btnTooltip: 'Toggle Forecasts',
    },
    {
      key: 'group',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.group.visible,
      toolbar: hotelSettings?.group.toolbar,
      btnIcon: HiOutlineUserGroup,
      btnTooltip: 'Toggle Group',
    },
    {
      key: 'gtdCxl',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.gtdCxl.visible,
      toolbar: hotelSettings?.gtdCxl.toolbar,
      btnIcon: MdFreeCancellation,
      btnTooltip: 'Toggle GTD/CXL',
    },
    {
      key: 'liveData',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.liveData.visible,
      toolbar: hotelSettings?.liveData.toolbar,
      btnIcon: CgLivePhoto,
      btnTooltip: 'Toggle Live Data',
    },
    {
      key: 'occ',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.occ.visible,
      toolbar: hotelSettings?.occ.toolbar,
      btnIcon: MdSensorOccupied,
      btnTooltip: 'Toggle OCC',
    },
    {
      key: 'occ_rc',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.occ_rc.visible,
      toolbar: hotelSettings?.occ_rc.toolbar,
      btnIcon: PiPercentFill,
      btnTooltip: 'Toggle ORC',
    },
    {
      key: 'pickup',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.pickup.visible,
      toolbar: hotelSettings?.pickup.toolbar,
      btnIcon: BsBoxArrowInUpRight,
      btnTooltip: 'Toggle Pickup',
    },
    {
      key: 'pickupDoa',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.pickupDoa.visible,
      toolbar: hotelSettings?.pickupDoa.toolbar,
      btnIcon: CgCalendarToday,
      btnTooltip: 'Toggle Pickup Day of Arrival',
    },
    {
      key: 'rateLevelStatus',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.rateLevelStatus.visible,
      toolbar: hotelSettings?.rateLevelStatus.toolbar,
      btnIcon: HiStatusOnline,
      btnTooltip: 'Toggle Rate Level Status',
    },
    {
      key: 'rates',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.rates.visible,
      toolbar: hotelSettings?.rates.toolbar,
      btnIcon: MdOutlineRateReview,
      btnTooltip: 'Toggle Rates API',
    },
    {
      key: 'rooms',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.rooms.visible,
      toolbar: hotelSettings?.rooms.toolbar,
      btnIcon: MdBedroomParent,
      btnTooltip: 'Toggle Room Types',
    },
    {
      key: 'str',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.str.visible,
      toolbar: hotelSettings?.str.toolbar,
      btnIcon: GiArchiveResearch,
      btnTooltip: 'Toggle STR',
    },
    {
      key: 'transient',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.transient.visible,
      toolbar: hotelSettings?.transient.toolbar,
      btnIcon: IoFootstepsSharp,
      btnTooltip: 'Toggle Transient',
    },
    {
      key: 'weather',
      type: 'TOGGLE',
      btnClass: toggleBtnClass,
      btnIconClass,
      enabled: hotelSettings?.weather.visible,
      toolbar: hotelSettings?.weather.toolbar,
      btnIcon: TiWeatherPartlySunny,
      btnTooltip: 'Toggle Weather',
    },
  ];

  return (
    <>
      <span className='relative inline-flex shadow-sm rounded-md'>
        <button
          type='button'
          className={beginBtnClass}
          data-tip='View previous dates'
          onClick={() => handleScrollDates('prev')}
        >
          <span className='sr-only'>Previous Dates</span>
          <FaArrowAltCircleUp className={btnIconClass} aria-hidden='true' />
        </button>
        <BtnToday
          btnClass={middleBtnClass}
          btnIconClass={btnIconClass}
          setTargetDate={setTargetDate}
        />
        <button
          type='button'
          className={endBtnClass}
          data-tip='View next dates'
          onClick={() => handleScrollDates('next')}
        >
          <span className='sr-only'>Next Dates</span>
          <FaArrowAltCircleDown className={btnIconClass} aria-hidden='true' />
        </button>
      </span>

      <span className='relative ml-1 inline-flex shadow-sm rounded-md'>
        {buttonProps.map((btnObj: any) => {
          return (
            <BtnToolbarToggle
              btnKey={btnObj.key}
              key={btnObj.key}
              btnClass={toggleBtnClass}
              btnIconClass={btnIconClass}
              enabled={btnObj.enabled}
              toolbar={btnObj.toolbar}
              btnIcon={btnObj.btnIcon}
              btnTooltip={btnObj.btnTooltip}
            />
          );
        })}
      </span>

      <span className='relative ml-1 inline-flex shadow-sm rounded-md'>
        <DateRangeSelector
          btnClass={`${baseBtnClass} rounded-md`}
          btnIconClass={btnIconClass}
          daysOut={daysOut}
          endDate={endDate}
          loading={isLoading}
          onChange={handleDateRangeChange}
          startDate={startDate}
        />
      </span>
      <span className='relative ml-1 inline-flex shadow-sm rounded-md'>
        <BtnResetRecs
          btnClass={middleBtnClass}
          btnIconClass={btnIconClass}
          filters={filters}
        />
      </span>
      <span className='relative ml-1 inline-flex shadow-sm rounded-md'>
        <BtnForecastTools
          btnClass={middleBtnClass}
          btnIconClass={btnIconClass}
          filters={filters}
          handleToggleSuperscript={handleToggleSuperscript}
        />
      </span>
    </>
  );
};

export default Toolbar;
