import { CellObject, HeaderObject } from '../../overview-table/hooks/use-table';
import { TbEye, TbEyeOff } from 'react-icons/tb';

import DataGrid from '../../../components/DataGrid';
import { DateHelpers } from '../../../helpers/dateHelpers';
import { OtaiLowestRatesQuery } from '../../overview-table/gql/_gen_/rate-shop.gql';
import RateDataTable from './rate-data-table';
import Tooltip from 'react-tooltip';
import clsx from 'clsx';
import { useState } from 'react';

type DetailTableProps = {
  brandCode: string;
  handleTableChange: (tableVersion: string) => void;
  headers: HeaderObject[];
  rateData?: OtaiLowestRatesQuery;
  rows: CellObject[][];
  tableVersion: string;
  todaysDate?: string;
};

export const DetailTable = ({
  brandCode,
  handleTableChange,
  headers,
  rateData,
  rows,
  tableVersion,
  todaysDate = DateHelpers.today(),
}: DetailTableProps) => {
  const toggleableColumns = ['occ', 'occ_rc'];
  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    headers.map((header) => header.dataKey)
  );
  const toggleColumn = (colKey: string) => {
    setVisibleColumns((prev: string[]) =>
      prev.includes(colKey)
        ? prev.filter((col) => col !== colKey)
        : [...prev, colKey]
    );
  };
  const filteredHeaders = headers.filter((header) =>
    visibleColumns.includes(header.dataKey)
  );
  const filteredRows = rows.map((row) =>
    row.filter((cell) => visibleColumns.includes(cell.dataKey))
  );
  return (
    <>
      <nav className='mb-2'>
        <div className='flex items-center gap-4'>
          <div className='flex gap-1'>
            <button
              className={clsx(
                'text-sm font-semibold px-2',
                tableVersion === 'detail' ? 'text-blue-900' : 'text-gray-400'
              )}
              onClick={() => handleTableChange('detail')}
              disabled={tableVersion === 'detail'}
            >
              Detail Table
            </button>
            <button
              className={clsx(
                'text-sm font-semibold px-2',
                tableVersion === 'rates' ? 'text-blue-900' : 'text-gray-400'
              )}
              onClick={() => handleTableChange('rates')}
              disabled={tableVersion === 'rates'}
            >
              Rate Data
            </button>
          </div>
          <div className='flex gap-.5 ml-64'>
            {headers
              .filter((header) => toggleableColumns.includes(header.dataKey))
              .map((header) => (
                <button
                  key={header.dataKey}
                  onClick={() => toggleColumn(header.dataKey)}
                  className={clsx(
                    'text-sm font-semibold px-2 flex items-center gap-.5',
                    visibleColumns.includes(header.dataKey)
                      ? 'text-blue-900'
                      : 'text-gray-400'
                  )}
                  data-tip={
                    visibleColumns.includes(header.dataKey)
                      ? `Hide ${header.title}`
                      : `Show ${header.title}`
                  }
                >
                  {visibleColumns.includes(header.dataKey) ? (
                    <TbEyeOff className='w-4 h-4 text-blue-900' />
                  ) : (
                    <TbEye className='w-4 h-4 text-gray-400' />
                  )}
                  {header.title}
                </button>
              ))}
            <Tooltip />
          </div>
        </div>
      </nav>

      {tableVersion === 'detail' ? (
        <DataGrid
          brandCode={brandCode}
          colToggles={{}}
          dateSegment='day'
          headers={filteredHeaders}
          monthOffset={0}
          rows={filteredRows}
          snapshotDate={todaysDate}
          sortDir='asc'
          sortKey='stay_date'
          targetDate={{
            value: todaysDate,
          }}
          startDate={DateHelpers.subtract(todaysDate, 'month', 1)}
          setSortDir={() => {}}
          setSortKey={() => {}}
        />
      ) : (
        <RateDataTable
          rateData={rateData || { dailyRates: [], compSet: [] }}
          brandCode={brandCode}
        />
      )}
    </>
  );
};
