import { z } from 'zod';

export const RmRecStatusSchema = z.enum([
  'OPEN',
  'PROCESSING',
  'ERROR',
  'ACCEPTED',
  'DECLINED',
  'UPLOADED',
]);

export const RmRecsDataSchema = z.object({
  id: z.coerce.number(),
  notes: z.string().optional().nullable(),
  rate: z.number().nullable(),
  status: RmRecStatusSchema,
  stay_date: z.string().date(),
});

export const GroupDataSchema = z.object({
  stay_date: z.string().date(),
  details: z.array(
    z.object({
      description: z.string().optional().nullable(),
      group_rooms_committed: z.number(),
      group_rooms_remaining: z.number(),
      group_rooms_sold: z.number(),
      is_past: z.boolean(),
      lowest_group_rate: z.number().optional().nullable(),
      rate_code: z.string().optional().nullable(),
      snapshot_date: z.string().optional().nullable(),
    })
  ),
});
