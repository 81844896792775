import { useEffect, useState } from 'react';

import { BiMailSend } from 'react-icons/bi';
import BtnSaveMeeting from './btn-save-meeting';
import { Dialog } from '@headlessui/react';
import { Email } from '../utils/email';
import { LoadingTooltip } from './loading-tooltip';
import { MdEmail } from 'react-icons/md';
import Modal from '../../../components/Modal';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHotel } from '../../../context/hotelContext';
import { useMeeting } from '../../meetings/context/meeting-context';
import { useOktaAuth } from '@okta/okta-react';
import { useReportData } from '../context/report-data-context';
import { useTrackMeetingEvent } from '../../tracking/hooks/use-track-event';
import { useUploadData } from '../../meetings/hooks/use-upload-data';
import { useUser } from '../../../context/userContext';

type SendEmailButtonProps = {
  btnClass: string;
  btnIconClass?: string;
  loading: Array<string>;
};

export const BtnEmailMeeting = ({
  btnClass,
  btnIconClass = 'h-5 w-5',
  loading,
}: SendEmailButtonProps) => {
  const { oktaAuth } = useOktaAuth();
  const [isSending, setIsSending] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { meeting, meetingTools, saveMeeting, loadingUpdateMeeting } =
    useMeeting();
  const { data, loading: dataLoading } = useReportData();
  const { user } = useUser();
  const { hotel } = useHotel();
  const { storeMeetingData } = useUploadData({
    data,
    dataLoading,
    hotel,
    meeting,
    meetingTools,
    user,
  });

  const track = useTrackMeetingEvent({ meeting, page: 'btn_share_meeting' });

  const readyToShare = (showToast = true) => {
    if (!meeting) {
      showToast && toastError('Meeting not found');
      return false;
    }
    if (!meetingTools) {
      showToast && toastError('Meeting tools not found');
      return false;
    }
    if (!user) {
      showToast && toastError('User not found');
      return false;
    }
    const { brandCode, meetingDate, bucket, bucketKey } = meeting;
    const { email: from } = user;

    if (!brandCode) {
      showToast && toastError('Brand code not found');
      return false;
    }
    if (!meetingDate) {
      showToast && toastError('Meeting date not found');
      return false;
    }
    if (!bucket || !bucketKey) {
      showToast && toastError('S3 bucket info not found');
      return false;
    }
    if (!from) {
      showToast && toastError('From email not found');
      return false;
    }
    return true;
  };

  const getEmailObject = () => {
    if (!readyToShare()) return;
    if (!meeting || !meetingTools || !user || !user.email) return;
    const { email: from } = user;

    return Email.createMailtoLink({
      from,
      meeting,
      meetingTools,
    });
  };

  const handleClick = async () => {
    setIsSending(true);
    setOpenModal(false);
    if (!readyToShare()) return;
    if (!meeting || !meetingTools || !user || !user.email) return;

    const { email: from } = user;

    const emailObject = getEmailObject();

    if (!emailObject) {
      toastError('Email object not created');
      return;
    }

    if (!emailObject.recipients) {
      toastError('No recipients found');
      return;
    }

    const payload = {
      emailSubject: emailObject.emailSubject,
      from: Email.REPORT_FROM_EMAIL,
      meetingDetails: emailObject.meetingDetails,
      meetingId: meeting.id!,
      recipients: emailObject.recipients as string[],
      reportLink: emailObject.reportLink,
      revenueManager: from,
      rmName: user.name || 'BWH Revenue Manager',
    };

    const token = oktaAuth.getAccessToken();

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/email`,
        payload,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
        }
      );

      toast(res.data.result);

      storeMeetingData && (await storeMeetingData());
      await handleSave();

      track('share_meeting', `meeting_${meeting.id}`);
    } catch (error) {
      toast.error('Failed to send email', { position: 'top-left' });
    }
    setIsSending(false);
  };

  const handleSave = async () => {
    await saveMeeting({ data, meetingTools });
  };

  const toastError = (msg: string) => {
    toast.error(msg, { position: 'top-left' });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [loading]);

  const ModalContent = () => {
    const emailObject = getEmailObject();

    return (
      <>
        <div className='max-w-[700px]'>
          <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
            <MdEmail className='h-6 w-6 text-green-600' aria-hidden='true' />
          </div>
          <div className='mt-3 text-center sm:mt-5'>
            <Dialog.Title
              as='h3'
              className='text-lg leading-6 font-medium text-gray-900'
            >
              Confirm Message Content
            </Dialog.Title>
            <div className='flex flex-col mt-4 text-left gap-2 max-h-[400px] border p-2 overflow-y-scroll'>
              <div className='text-gray-600'>Recipients:</div>
              <div className='border p-1 font-semibold'>
                <ul>
                  {user?.email && <li>You: {user.email}</li>}
                  {emailObject?.recipients?.length ? (
                    emailObject?.recipients?.map((recipient, index) => (
                      <li key={index}>{recipient}</li>
                    ))
                  ) : (
                    <li className='text-red-800 text-xs'>
                      No additional recipients
                    </li>
                  )}
                </ul>
              </div>
              <div className='text-gray-600'>Subject:</div>
              <div
                className='border p-1 font-semibold'
                dangerouslySetInnerHTML={{
                  __html: emailObject?.emailSubject || 'No Subject',
                }}
              />
              <div className='text-gray-600'>Message Body:</div>
              <div
                className='border p-1'
                dangerouslySetInnerHTML={{
                  __html: emailObject?.messageBody || 'No Body',
                }}
              />
              <div className='text-gray-600 mt-2'>Clickable Report Link:</div>
              <div className='border p-1 font-semibold'>
                <a
                  href={emailObject?.reportLink}
                  target='_blank'
                  rel='noreferrer'
                  className='text-blue-500 underline'
                >
                  Report Link
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
          <button
            type='button'
            disabled={isSending}
            className='w-full inline-flex disabled:cursor-not-allowed justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:col-start-2 sm:text-sm'
            onClick={() => handleClick()}
          >
            Send Email
          </button>
          <button
            type='button'
            className='mt-3 w-full inline-flex justify-center rounded-md border border-red-600 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:col-start-1 sm:text-sm'
            onClick={() => setOpenModal(false)}
          >
            Do Not Send
          </button>
        </div>
      </>
    );
  };

  const ShareLinkBtn = () => {
    return (
      <>
        <span
          onClick={() => setOpenModal(true)}
          className={`${btnClass} ${'text-blue-500'} ${
            loading.length
              ? 'opacity-50 text-orange-500 cursor-progress animate-spin'
              : 'cursor-pointer'
          }`}
          data-tip={'Send Email'}
        >
          <BiMailSend className={btnIconClass} aria-hidden='true' />
        </span>
        <LoadingTooltip buttonType='Email' loading={loading} />
      </>
    );
  };

  if (readyToShare(false)) {
    if (loadingUpdateMeeting) {
      return <span>Updating...</span>;
    }
    return (
      <span>
        <ShareLinkBtn />
        <Modal
          content={ModalContent()}
          open={openModal}
          setOpen={setOpenModal}
        />
      </span>
    );
  } else {
    return <BtnSaveMeeting btnClass={btnClass} loading={loading} />;
  }
};
