import { BtnGenerateNotes } from './btn-generate-notes';
import { BtnInsertNotepad } from './btn-insert-notepad';
import { BtnInsertNotes } from './btn-insert-notes';
import ReactTooltip from 'react-tooltip';
import { SavedStatus } from './saved-status';
import { Status } from '../types/status';

interface NotesToolbarProps {
  handleInsert?: (source: 'notepad' | 'prevNotes') => void;
  handleSave: () => Promise<void>;
  parentName?: string;
  status?: Status;
}

export const NotesToolbar = ({
  handleInsert,
  handleSave,
  parentName = '',
  status,
}: NotesToolbarProps) => {
  return (
    <div id={`toolbar${parentName}`} className='notes-toolbar flex'>
      <select className='ql-size' defaultValue='normal'>
        <option value='small'></option>
        <option value='normal'></option>
        <option value='large'></option>
        <option value='huge'></option>
      </select>
      <button className='ql-bold' />
      <button className='ql-italic' />
      <button className='ql-underline' />
      <button className='ql-strike' />
      <button className='ql-link' />
      <button className='ql-list' value='ordered' />
      <button className='ql-list' value='bullet' />
      <button className='ql-indent' value='-1' />
      {handleInsert ? (
        <BtnInsertNotes handleInsert={() => handleInsert('prevNotes')} />
      ) : null}
      {handleInsert ? (
        <BtnInsertNotepad handleInsert={() => handleInsert('notepad')} />
      ) : null}
      <BtnGenerateNotes triggerGeneration={undefined} />
      <SavedStatus status={status} handleSave={handleSave} />
      <ReactTooltip id='notes-toolbar-tooltip' />
    </div>
  );
};
