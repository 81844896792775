import { CustomColumnSettings, HotelSettings } from '../../table-settings';
import { DateSegment, dayRange } from '../../../helpers/dateHelpers';
import _, { split } from 'lodash';
import { baseFormat, getRowClass } from '../../../renderers/baseFormat';

import { masterColumns } from '../../../renderers/masterColumns';

type TableProcessorProps = {
  customHeaders: CustomColumnSettings[] | null;
  data: any;
  dateSegment: string;
  endDate: string;
  hotelSettings?: HotelSettings;
  startDate: string;
  detailCols?: Array<any>;
  includeSummary?: boolean;
};

export type TableCell = {
  cls: string;
  dataKey: string;
  displayValue: string | null;
  meta?: any;
  stayDate: string;
  value: string | number | null;
};

export type TableHeader = {
  columnSize: number;
  dataKey: string;
  meta?: any;
  title: string;
  tooltip: string;
};

export const processTable = (props: TableProcessorProps) => {
  const {
    customHeaders,
    data,
    dateSegment,
    endDate,
    hotelSettings,
    startDate,
    detailCols,
    includeSummary = true,
  } = props;

  const tableCols = hotelSettings?.tableCols || detailCols;
  let headers: TableHeader[] = [];
  let rows: TableCell[][] = [];

  if (!data || _.isEmpty(data) || !tableCols) {
    return { headers, rows };
  }

  // Process headers
  headers = tableCols.map((column) => {
    const { key, dataSource, header, tooltip } = column;
    const masterColumn = masterColumns[key];

    let customColumn = customHeaders?.find(
      (h) => h.key === key || split(h.key)[1] === key
    );

    const mergedColumn = {
      ...masterColumn,
      ...(customColumn && _.omitBy(customColumn, _.isNull)),
      ...(dataSource && { dataSource }),
      ...(header && { header }),
      ...(tooltip && { tooltip }),
    };

    return {
      columnSize: mergedColumn?.columnSize || 55,
      dataKey: key,
      meta: { ...column, ...mergedColumn },
      title: mergedColumn?.header || '',
      tooltip: (mergedColumn?.tooltip as string) || '',
    };
  });

  // Process rows
  const stayDates = dayRange(
    startDate,
    endDate,
    dateSegment as DateSegment,
    includeSummary
  );

  rows = stayDates.map((stayDate, dateIdx) => {
    const isSummary = stayDate.length === 7 && dateSegment === 'day';

    return tableCols.map((column): TableCell => {
      const { key, dataSource } = column;
      const categoryData = data[dataSource];

      let value: string | number | null = null;
      let displayValue: string | null = null;

      if (categoryData?.length) {
        const targetData =
          dateSegment === 'day'
            ? isSummary
              ? categoryData.filter(
                  (o: any) => o.date_ym === stayDate && o.stay_date !== null
                )
              : categoryData.find((o: any) => o.stay_date === stayDate)
            : categoryData;

        if (targetData) {
          if (dataSource === 'dailyRates') {
            const rateDetails = column;
            const compId = rateDetails.competitor_id;
            if (isSummary) {
              if (key === 'stay_date') {
                value = stayDate + '-999';
              } else {
                const rates = targetData
                  .map((d: any) =>
                    d.rates.find((r: any) => r.competitor_id === compId)
                  )
                  .filter(Boolean);
                value = _.meanBy(rates, 'rate') || null;
              }
            } else {
              const rates = targetData.rates;
              const rateData = _.find(rates, { competitor_id: compId });
              value = key === 'stay_date' ? stayDate : rateData?.rate;
            }
          } else if (isSummary) {
            if (key === 'stay_date') {
              value = stayDate + '-999';
            } else if (key.includes('adr')) {
              // ADR calculation from useTable
              if (key.includes('var_')) {
                const revValue = _.sumBy(targetData, 'revenue');
                const soldValue = _.sumBy(targetData, 'sold');
                const currentYearADR =
                  soldValue > 0 ? revValue / soldValue : null;

                const revLastYear = _.sumBy(
                  targetData,
                  key.replace('adr', 'revenue').replace('var_', '')
                );
                const soldLastYear = _.sumBy(
                  targetData,
                  key.replace('adr', 'sold').replace('var_', '')
                );
                const lastYearADR =
                  soldLastYear > 0 ? revLastYear / soldLastYear : null;

                value =
                  currentYearADR && lastYearADR
                    ? currentYearADR - lastYearADR
                    : null;
              } else {
                const revValue = _.sumBy(
                  targetData,
                  key.replace('adr', 'revenue')
                );
                const soldValue = _.sumBy(
                  targetData,
                  key.replace('adr', 'sold')
                );
                value = soldValue > 0 ? revValue / soldValue : null;
              }
            } else if (key.includes('rate') || key.includes('occ')) {
              value =
                _.meanBy(targetData, key) ||
                _.meanBy(targetData, key.split('.')[1]);
            } else {
              value =
                _.sumBy(targetData, key) ||
                _.sumBy(targetData, key.split('.')[1]);
            }
          } else {
            value =
              _.get(targetData, key) || _.get(targetData, key.split('.')[1]);
          }

          // Format the display value after we have a proper value
          const formattedValue = baseFormat(
            key,
            value,
            dateSegment,
            column,
            isSummary
          );
          displayValue = formattedValue ? String(formattedValue) : null;
        }
      }

      return {
        cls: getRowClass(
          stayDate,
          dateIdx,
          dateSegment,
          value as number | undefined,
          isSummary
        ),
        dataKey: key,
        displayValue,
        meta: { ...column, isSummary },
        stayDate,
        value, // This should now have proper values
      };
    });
  });

  return { headers, rows: rows.filter((row) => _.compact(row).length > 0) };
};
