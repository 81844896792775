import React, { createContext, useContext, useEffect, useState } from 'react';

import { useComputeFeatureFlagsQuery } from '../gql/_gen_/feature-flags.gql';
import { useUser } from './userContext';

type Flags = {
  'admin-menu': boolean;
  'ai-notes': boolean;
  'event-table-upgrade': boolean;
  'meeting-notes-upgrade': boolean;
  'meeting-panel-upgrade': boolean;
  'rate-change-superscript': boolean;
};

type FlagContextType = {
  flags?: Flags;
  loading: boolean;
  error?: Error | null;
};

const FlagContext = createContext<FlagContextType>({
  flags: undefined,
  loading: true,
  error: null,
});

export const FlagProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useUser();
  const [flags, setFlags] = useState<Flags>();
  const { loading, error, data, refetch } = useComputeFeatureFlagsQuery();

  useEffect(() => {
    if (data && data.computeFeatureFlags) {
      const newFlags = data.computeFeatureFlags.reduce<Flags>((acc, flag) => {
        if (flag && flag.key && typeof flag.computed_value === 'boolean') {
          acc[flag.key as keyof Flags] = flag.computed_value;
        }
        return acc;
      }, {} as Flags);
      setFlags(newFlags);
    }
  }, [data]);

  useEffect(() => {
    if (user) {
      refetch();
    }
  }, [user, refetch]);

  return (
    <FlagContext.Provider value={{ flags, loading, error: error || null }}>
      {children}
    </FlagContext.Provider>
  );
};

export const useFlags = () => useContext(FlagContext);
