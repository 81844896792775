import BtnDeleteMeeting from './btn-delete-meeting';
import { BtnEmailMeeting } from './btn-email-meeting';
import BtnReuploadMeeting from './btn-reupload-meeting';
import { DateHelpers } from '../../../helpers/dateHelpers';
import { Meeting } from '../../../graphql/types';
import ReactTooltip from 'react-tooltip';
import { State as UserState } from '../../../context/userContext';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useIsShared } from '../hooks/use-report-location';

interface MeetingStatsProps {
  loading: {
    meetingLoading: boolean;
    Events: boolean;
    Groups: boolean;
    'Heat Map': boolean;
    'Hotel Budget': boolean;
    'Last Night Summary': boolean;
    'Monthly Performance': boolean;
    'Sold, ADR, Rev': boolean;
    'Detail Table': boolean;
    'RM Recs': boolean;
    Tonight: boolean;
    'Last Night': boolean;
  };
  meeting?: Meeting;
  user?: UserState;
  isAdmin?: boolean;
}

const MeetingStats = ({
  loading,
  meeting,
  user,
  isAdmin,
}: MeetingStatsProps) => {
  const { isShared } = useIsShared();

  useEffect(() => {
    if (loading.meetingLoading === false) {
      ReactTooltip.rebuild();
    }
  }, [loading.meetingLoading, meeting]);

  const baseBtnClass =
    'relative inline-flex items-center mx-1 text-sm font-medium text-gray-500 focus:z-10';

  const loadingList = Object.entries(loading)
    .filter(([, value]) => value === true)
    .map(([key]) => key);

  const Loading = () => {
    return (
      <div className='border-t border-gray-200 bg-gray-100 grid grid-cols-1 divide-y divide-gray-200'>
        <div className='px-6 py-2 text-sm font-medium text-center'>
          <span className='text-gray-600'>
            Checking for existing meeting...
          </span>
        </div>
      </div>
    );
  };

  if (loading.meetingLoading) {
    return <Loading />;
  }

  if (meeting && meeting.meetingDate && meeting.createdAt) {
    return (
      <div
        className={clsx(
          'border-t border-gray-200 bg-gray-100 grid grid-cols-1 divide-y divide-gray-200 sm:divide-y-0 sm:divide-x',
          isShared ? 'sm:grid-cols-3' : 'sm:grid-cols-4'
        )}
      >
        <div className='px-6 py-2 text-sm font-medium text-center'>
          <span className='text-gray-600'>For: </span>
          <span className='text-gray-900'>
            {DateHelpers.displayDate(meeting.meetingDate)}
          </span>
        </div>
        <div className='px-6 py-2 text-sm font-medium text-center'>
          <span className='text-gray-600'>By: </span>
          <span className='text-gray-900'>{user?.name}</span>
        </div>
        <div className='px-6 py-2 text-sm font-medium text-center'>
          <span className='text-gray-600'>Created: </span>
          <span className='text-gray-900'>
            {DateHelpers.displayDate(meeting.createdAt)}
          </span>
        </div>
        {!isShared && (
          <div className='px-6 py-2 text-sm font-medium text-center'>
            <BtnEmailMeeting btnClass={baseBtnClass} loading={loadingList} />
            <BtnReuploadMeeting btnClass={baseBtnClass} loading={loadingList} />
            {isAdmin && <BtnDeleteMeeting btnClass={baseBtnClass} />}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className='border-t border-gray-200 bg-gray-100 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x'>
        <div className='px-6 py-2 text-sm font-medium text-center'>
          <span className='text-gray-600'>For: </span>
          <span className='text-gray-900'></span>
        </div>
        <div className='px-6 py-2 text-sm font-medium text-center'>
          <span className='text-gray-600'>By: </span>
          <span className='text-gray-900'></span>
        </div>
        {!isShared && (
          <div className='px-6 py-2 text-sm font-medium text-center'>
            <BtnEmailMeeting btnClass={baseBtnClass} loading={loadingList} />
          </div>
        )}
      </div>
    );
  }
};

export default MeetingStats;
